export function convertEnumToArray<T = string, E = T>(enumeration: Record<string, E>, nullable?: boolean): (T | null)[] {
  const values = Object.values(enumeration) as unknown as T[];
  return nullable ? [null, ...values] : values;
}

/**
 * Joins all values with a comma, adds 'and' before last value, except when only one element, ie.
 * ['one', 'two', 'three'] -> 'one, two, and three' || ['one'] -> 'one' || [] -> ''
 */
export const enumerateValuesToString = (values: string[]) => values.map((v, i) => (values.length - 1 === i && values.length > 1 ? `and ${v}` : v)).join(', ');


// Async filter function
// Source : https://advancedweb.hu/how-to-use-async-functions-with-array-filter-in-javascript/
export const asyncFilter = async <T>(arr: T[], predicate: (i: T) => Promise<boolean>) => {
  const results = await Promise.all(arr.map(predicate));
  return arr.filter((_v, index) => results[index]);
};
