import { JWEnvironment } from './environment.model';

export const environment: JWEnvironment = {
  production: false,
  apiUrlLegacy: 'https://api.core-legacy-api-staging.dev.joorney-tech.com/api',
  coreApiUrl: 'https://api.core-api-staging.dev.joorney-tech.com/api',
  wrikeApiUrl: 'https://api.jdata-api-staging.dev.joorney-tech.com/api',
  orchestratorApiUrl: 'https://api.orchestrator-api-staging.dev.joorney-tech.com/api',
  msal: {
    tenantId: '1a433241-37f3-4dda-922a-1af8ce780226',
    clientId: 'c5398af4-b8dc-4c81-b28c-dd3d74916329',
    customScope: 'user_impersonation',
  },
  apiKeys: {
    googleTagManager: 'GTM-N62PCZF',
    sentryDSN: 'https://c75abe6e9eaa4bc7aacbc1961f34f28d@o4504949466529792.ingest.sentry.io/4504949471510528',
    sentryEnvironment: 'STAGING',
  },
};
